// TODO: разбораться в задаче http://jira.rn/browse/REGIONNEWS-24538
/* eslint-disable */
import './set-public-path';

import { Workbox } from 'workbox-window';

import('./web-vitals');

if (typeof navigator !== 'undefined' && 'serviceWorker' in navigator) {
  if (window.jtnConfig.regionId === 29) {
    const wb = new Workbox('/service-worker.js');

    wb.addEventListener('waiting', () => {
      wb.messageSkipWaiting();
    });

    wb.register()
      .then(reg => {
        // eslint-disable-next-line
        console.info('Successful service worker registration', reg);
      })
      .catch(err => console.error('Service worker registration failed', err));
  } else {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        if (registration.active?.scriptURL.indexOf('service-worker.js') !== -1) {
          registration.unregister();
        }
      }
    });

    if ('caches' in window) {
      caches.keys().then(function (keyList) {
        return Promise.all(
          keyList.map(function (key) {
            return caches.delete(key);
          })
        );
      });
    }
  }
}

const render = async () => {
  if (window.jtnConfig.isNewApp) {
    return (await import(/* webpackChunkName: "new-app" */ './client-new.entry')).render();
  }

  return (await import(/* webpackChunkName: "old-app" */ './client-deprecated.entry')).render();
}


const isIntersectionObserverNotSupported = () =>
  !('IntersectionObserver' in window) ||
  !('IntersectionObserverEntry' in window) ||
  !('intersectionRatio' in window.IntersectionObserverEntry.prototype);

if (
  !isIntersectionObserverNotSupported() &&
  ('flatMap' in Array.prototype)
) {
  render();
} else {
  import(/* webpackChunkName: "polyfills" */ './polyfills').then(render);
}
